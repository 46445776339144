<template>
  <div>
    <Loading/>
  </div>
</template>

<script>
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";
import Loading from "@/components/Page/Liff/Shared/Loading";

export default {
  data() {
    return {
     accessToken: null,
     profile: null,
    }
  },
  components: {
    Loading,
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const orgCode = this.$route.params.org_code;
      const urlCode = this.$route.params.url_code;

      const checker = new LiffChecker(orgCode);
      await checker.initLiff();
      try {
        let url = process.env.VUE_APP_API_BASE_URL + `/${orgCode}/liff/url/${urlCode}`;
        let {accessToken, profile} = await checker.getLineData()
        this.accessToken = accessToken;
        this.profile = profile;
        console.log(accessToken, profile);

        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (response.data.data) {
          console.log(response.data.data);
          let shortUrl = response.data.data;
          let dest = new URL(shortUrl.redirect)
          console.log('route', this.$route);
          Object.entries(this.$route.query).forEach(([key, value]) => {
            // 這是 LINE LIFF OAUTH 關鍵字，如果繼續帶會有重複的 code 跟 state
            // 導致接續 OAUTH 失敗
            if (!['state', 'code', 'liffClientId', 'liffRedirectUri'].includes(key)) {
              dest.searchParams.append(key, value)
            }
          })
          console.log('short_url', dest.toString());
          if (!this.$route.query.skip) {
            window.location.href  = dest.toString()
          }
        } else {
          throw false
        }
      } catch (e) {
        alert('短網址導向失敗');
        console.error(e);
      }
    },
  }
}
</script>
